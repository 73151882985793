<template>
  <v-main>
      <v-container>
          <RegistrationSuccess />
      </v-container>
  </v-main>
</template>

<script>
import RegistrationSuccess from '../components/Registration/RegistrationSuccess.vue'
export default {
    components: {
        RegistrationSuccess,
    }

}
</script>

<style>

</style>